import "./Home.css"
import Header from "../components/Header"
import MainHero from "../components/MainHero"
import TechStackHero from "../components/TechStackHero"

function Home() {
    return(
        <div id="HomePage">
            <Header />
            <MainHero />
            <TechStackHero />
        </div>
    ) 
};

export default Home;