import "./Header.css"


function Header() {
    return(
        <div id="Header">
            <a id="LogoA" href="/"><span id="Logo"><span id="TrigramFire">☲</span>Polideck Technologies</span></a>
            <div id="HeaderNavMenu">
                <a class="NavItem" href="https://news.polideck.com">Articles</a>
                <a class="NavItem" href="/docs" className="hide">Documentation</a>
                <a class="NavItem" href="/contact" className="hide">Contact</a>
            </div>
        </div>
    ) 
};

export default Header;