import "./MainHero.css"
import missionImage from "./missionImage.jpg"

function MainHero() {
    return(
        <div id="MainHero">
            <div id="MainHeroText">
                <h2 id="MainHeroHeading">Zero Trust Identity For The Quantum Era.</h2>
                <h2 id="MainHeroSubText">Designed and developed from the ground up to maximize flexibility and safeguard national security operations.</h2>
            </div>
            <img id="MainHeroImage" src={missionImage} alt="Helicopter flying over desert"></img>
        </div>
    ) 
};

export default MainHero;