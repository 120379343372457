import "./TechStackHero.css"
import TechStack from "./satellite.jpeg"

function MainHero() {
    return(
        <div id="TechStackHero">
            <div id="TechStackHeroText">
                <h2 id="TechStackHeroHeading">Protecting Assets Is Our Top Priority</h2>
                <h2 id="TechStackHeroSubText">Reach misison objectives faster and securely with unified asset identification, authentication, and encrypted communication.</h2>
            </div>
            <img id="TechStackHeroImage" src={TechStack} alt="Helicopter flying over desert"></img>
        </div>
    ) 
};

export default MainHero;